import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row-reverse lg:gap-10 space-y-14 lg:space-y-0 lg:space-x-10 items-center relative lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-4">
          <h2 className="text-5xl !leading-tight font-bold text-white md:text-4xl xl:text-5xl text-text-color">
            {heading}
          </h2>
          <span className="block leading-6 text-base text-justify xl:text-lg text-white-6000 dark:text-white-400">
            {subHeading}
          </span>
        </div>
        <div className="flex-grow" style={{ marginLeft: "0px" }}>
          <img className="w-full rounded-xl" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
