import React, { FC, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { useApp } from "data/app-provider";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, logoutCall, setIsAuthPanelOpen } = useApp();
  const navigate = useNavigate();
  return (
    <div className={`nc-MainNav1 relative ${className}`}>
      <div className="px-4 lg:container py-2 lg:py-5 relative flex justify-between items-center">
        <div className=" md:flex justify-start items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>
        <div className="hidden md:flex items-center">
          <Navigation />
        </div>

        <div className="md:flex flex-shrink-0 items-center justify-end  lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            {/* <SwitchDarkMode /> */}
            {/* <SearchDropdown /> */}
            <div className="px-2" />
            {user && (
              <AvatarDropdown />

              // <div>

              //   <Popover className="relative">
              //     {({ open }) => (
              //       <>
              //         <PopoverButton
              //           className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              //         >
              //           <img
              //             src={
              //               user?.photoURL ??
              //               "https://img.freepik.com/premium-vector/avatar-profile-colorful-illustration-2_549209-82.jpg"
              //             }
              //             alt=""
              //             className="w-8 h-8 rounded-lg overflow-hidden mr-2"
              //           />
              //           <div className="px-1" />

              //           {user ? (
              //             user.displayName
              //           ) : (
              //             <ButtonPrimary href="/signup">Sign up</ButtonPrimary>
              //           )}
              //         </PopoverButton>
              //         <Transition
              //           as={Fragment}
              //           enter="transition ease-out duration-200"
              //           enterFrom="opacity-0 translate-y-1"
              //           enterTo="opacity-100 translate-y-0"
              //           leave="transition ease-in duration-150"
              //           leaveFrom="opacity-100 translate-y-0"
              //           leaveTo="opacity-0 translate-y-1"
              //         >
              //           <PopoverPanel className="absolute z-10 w-screen max-w-[150px] mt-1 -right-10 sm:right-0 px-4 bg-white dark:bg-neutral-800 top-full py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
              //             <span
              //               onClick={() => {
              //                 logoutCall(navigate);
              //               }}
              //             >
              //               Logout
              //             </span>
              //           </PopoverPanel>
              //         </Transition>
              //       </>
              //     )}
              //   </Popover>
              // </div>
            )}
            {!user && (
              <ButtonPrimary
                onClick={() => {
                  setIsAuthPanelOpen(true);
                }}
              >
                SignIn/SignUp
              </ButtonPrimary>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            {/* <SwitchDarkMode /> */}
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
