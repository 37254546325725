import React from 'react'

function BoyntonBeachContent() {
  return (
    <div>
    {" "}
    <div className="p-8 max-w-3xl mx-auto">
      <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
      Best Hotels in Boynton Beach Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-white">
        Hampton Inn & Suites Boynton Beach 1475 West Gateway Blvd Boynton Beach, FL 33426 United States
        </li>
        
        <li className="text-lg font-medium text-white mt-8">
        Hampton Inn & Suites Boynton Beach 1475 West Gateway Blvd Boynton Beach, FL 33426 United States
        </li>
        
        <li className="text-lg font-medium text-white mt-8">
        Hampton Inn & Suites Boynton Beach 1475 West Gateway Blvd Boynton Beach, FL 33426 United States
        </li>
        
      </ul>

      <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
      Best Restaurants in Boynton Beach Florida:
      </span>
      <ul className="list-decimal">
        <li className="text-lg font-medium text-white">
        Josie’s Ristorante 650 E Woolbright Rd Boynton Beach, FL 33435 United States
        </li>
        <li className="text-lg font-medium text-white mt-8">
        Nicholson Muir Distinguished Meats 480 E Ocean Ave Boynton Beach, FL 33435 United States
        </li>
        <li className="text-lg font-medium text-white mt-8">
        Nicholson Muir Distinguished Meats 480 E Ocean Ave Boynton Beach, FL 33435 United States
        </li>
      </ul>
    </div>
  </div>
  )
}

export default BoyntonBeachContent