import React from "react";
import { Link } from "react-router-dom";
import Logopic from "../../images/logo.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0`}
    >
      <img width={150} src={Logopic} />
    </Link>
  );
};

export default Logo;
