import React from "react";
import { hydrate, render } from "react-dom";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import { AppProvider } from "data/app-provider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { globalJson } from "global/global_json";

const stripePromise = loadStripe(globalJson.stripePublishableKey);

const rootElement = document.getElementById("root") as HTMLElement;

const AppElement = (
  <Elements stripe={stripePromise}>
    <AppProvider>
      <App />
      <Toaster />
    </AppProvider>
  </Elements>
);

// Check if SSR is used (existing HTML content in the root)
if (rootElement.hasChildNodes()) {
  hydrate(AppElement, rootElement);
} else {
  render(AppElement, rootElement);
}

// Measure performance (optional)
reportWebVitals();
