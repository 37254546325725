import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionComparison: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36 flex flex-col`}
      data-nc-id="SectionHero"
    >
      <div className=" space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <h2 className="text-3xl !leading-tight font-semibold text-white md:text-4xl xl:text-5xl dark:text-neutral-100 align-middle mb-1 lg:mb-24 ">
          {heading}
        </h2>
        <div className="flex-grow">
          <img className="w-full  rounded-md" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionComparison;
