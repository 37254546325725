import React from 'react'

function WestPalmBeachContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
        Best Hotels in West Palm Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
          The Ben 251 N Narcissus Ave West Palm Beach, FL 33401 United States
          </li>
          
          <li className="text-lg font-medium text-white mt-8">
          The Breakers Hotel & Resort 1 SCounty RdPalm Beach, FL 33480 United States
          </li>
          
          <li className="text-lg font-medium text-white mt-8">
          Eau Palm Beach Resort & Spa 100 S Ocean Blvd Manalapan, FL 33462 United States
          </li>
          
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
        Best Restaurants in West Palm Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
          Loic Bakery Cafe Bar 480 Hibiscus St Unit 116 West Palm Beach, FL 33401 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
          Buccan 350 S County Rd Palm Beach, FL 33480 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
          Kapow! Noodle Bar 519 Clematis St West Palm Beach, FL 33401 United States
          </li>
        </ul>
      </div>
    </div>
  )
}

export default WestPalmBeachContent