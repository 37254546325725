import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import readytodrive from "images/rentingcar.png";
import { globalJson } from "global/global_json";

export interface SectionHeroProps {
  className?: string;
}

const ReadytoDrive: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}  `}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left gap-0 lg:gap-20 xl:gap-40">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-[#E5E7EB]">
            Renting A Car Has Never Been Easier
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 text-center lg:text-left">
            To book a car click the button below or call us at {globalJson.phone}
          </span>
          <ButtonPrimary
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className=" w-full nc-Button relative h-auto inline-flex items-center justify-center rounded-xl transition-colors px-6 py-3 text-sm sm:text-base font-medium"
          >
            Get Started
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full rounded-xl" src={readytodrive} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ReadytoDrive;
