import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import BocoRatonContent from "components/LocationContent/BocoRatonContent";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import LocationSectionHero3 from "components/SectionHero/LocationSectionHero3";
import ReadytoDrive from "components/SectionHero/ReadytoDrive";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import { Helmet } from "react-helmet";

function BocaRaton() {
  return (
    <>
     <Helmet>
        <title>Boca Raton Car Rental | Places Rentals</title>
        <meta
          name="description"
          content=" An all NEW Car Rental Experience: Places Rentals Boco Raton offers contactless pickup and drop-off getting you on your way faster than ever! Economy Sedans, SUVs, Exotic, MiniVan + more"
        />
        <meta name="robots" content="index, follow" />
       
      </Helmet>
      <LocationSectionHero3 className="" name="Boca Raton Car Rental" />
      <div className="py-16">
        <GoPlacesWith />
      </div>
      <div className="py-16">
        <BocoRatonContent />
      </div>
      <div className="container relative pt-16 pb-8">
        <SectionGridFeaturePlaces />
      </div>
      <div className="relative py-16 mb-20 lg:mb-36">
        <BackgroundSection />
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <div className="container relative pt-16 pb-8">
        <ReadytoDrive
        />
      </div>
    </>
  );
}

export default BocaRaton;
