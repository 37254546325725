import { useEffect } from 'react';
import { scriptHubSpot } from 'global/global_json';

const HubSpotBot = () => {
  useEffect(() => {
    const script = scriptHubSpot;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default HubSpotBot;
