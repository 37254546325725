import React from "react";

function HollywoodContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Hotels in Hollywood Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            DoubleTree Resort by Hilton Hollywood Beach 4000 S Ocean Dr
            Hollywood, FL 33019 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            The Diplomat Beach Resort Hollywood, FL by Hilton 3555 S Ocean Dr
            Hollywood, FL 33019 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            Margaritaville Hollywood Beach Resort 1111 N Ocean Dr Hollywood, FL
            33019 United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Restaurants in Hollywood Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            LeTub (Waterfront with a view) 1100 N Ocean Dr Hollywood, FL 33019
            United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            The Modern Rose Cafe 4300 Hollywood Blvd Hollywood, FL 33021 United
            States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            G7 Rooftop 5510 S SR-7 Hollywood, FL 33314 United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HollywoodContent;
