import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useApp } from "data/app-provider";
import { Insurance, StripeVerification } from "models/FleetwireCustomer";
import { BonzahProduct } from "models/BonzahProducts";
// Create styles

const signatureStylesText = [
  "Playball",
  "Qwitcher Grypen",
  "Montez",
  "My Soul",
  "Ms Madi",
  "Niconne",
];

// // Register fonts with direct URLs
Font.register({
  family: "Playball",
  src: "/fonts/Playball-Regular.ttf",
});
Font.register({
  family: "Qwitcher Grypen",
  src: "/fonts/QwitcherGrypen-Regular.ttf",
});
Font.register({
  family: "Montez",
  src: "/fonts/Montez-Regular.ttf",
});
Font.register({
  family: "My Soul",
  src: "/fonts/MySoul-Regular.ttf",
});
Font.register({
  family: "Ms Madi",
  src: "/fonts/MsMadi-Regular.ttf",
});
Font.register({
  family: "Niconne",
  src: "/fonts/Niconne-Regular.ttf",
});

// Create Document Component

export interface RentalAgreemaentProps {
  selectedSignatureIndex: number;
  stripeVerification: StripeVerification;
  selectedBonzahProduct: BonzahProduct[];
  axleInsurance: Insurance | null;
  bonzahCertificate?: string;
  savingPDF?: boolean;
}
const RentalAgreement: React.FC<RentalAgreemaentProps> = ({
  selectedSignatureIndex,
  stripeVerification,
  selectedBonzahProduct,
  axleInsurance,
  bonzahCertificate,
  savingPDF,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      flexDirection: "column",
      display: "flex",
    },
    heading1: {
      fontWeight: "bold",
      fontSize: 22,
      color: savingPDF ? "#000000" : "#ffffff",
    },
    heading2: {
      fontWeight: "bold",
      fontSize: 22,
      color: "#FF0000",
    },
    heading3: {
      fontWeight: "bold",
      fontSize: 14,
      color: savingPDF ? "#000000" : "#ffffff",
    },
    text: {
      fontSize: 14,
      color: savingPDF ? "#000000" : "#ffffff",
    },
    textBold: {
      fontWeight: "bold",
      fontSize: 14,
      color: savingPDF ? "#000000" : "#ffffff",
    },
    section: {
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      bordercolor: savingPDF ? "#000000" : "#ffffff",
      marginBottom: 10,
      flexDirection: "column",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableCol: {
      flex: 1,
      borderStyle: "solid",
      borderWidth: 1,
      bordercolor: savingPDF ? "#000000" : "#ffffff",
    },
    tableCell: {
      margin: 5,
      fontSize: 14,
    },
    tableHeader: {
      fontWeight: "bold",
      backgroundColor: savingPDF ? "#ffffff" : "#0a1e36",
    },
  });

  return (
    <Document>
      <Page style={{ ...styles.page }}>
        <View style={styles.section}>
          <Text style={styles.heading1}>Places Rentals</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Please note the following Booking Terms and Conditions apply to
            bookings placed directly on the Places Rentals website. For bookings
            placed through a third-party website, please refer to their
            advertised Booking Terms and Conditions or your Rental Voucher.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            All bookings are subject to the Rental Agreement and Rental Jacket
            Terms and Conditions, and any other documents provided at the time
            of rental.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            You must meet all rental terms and conditions and local requirements
            at the time of rental or else your reservation will be canceled.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Drivers License / ID Restrictions</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            All renters must be in possession of a valid, original driver’s
            license including the photo ID. Driver’s licenses{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            must be in English or in the Roman alphabet.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            International Driving Permit is required when a driving license is
            not in English, Latin or Roman alphabet.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            The renter is solely responsible to ensure that their license is
            valid for use in the respective country and to determine whether an
            International Driving Permit is required by local law. Failure to
            provide a valid license and IDP permit (as required) will result in
            a non-refundable termination of the reservation.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            Note: An IDP must be obtained in your home country before departing
            for the rental location. Your driver’s license must be valid for the
            entire rental period.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You may be denied rental for reasons including, but not limited to
            the following:
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            - Previous driving disqualifications
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            - Pending prosecution or police enquiry
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            - Convictions for offences relating to theft, fraud or dishonesty
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            - Involved in an at-fault accident within the last 5 years
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            - Previous rental history covering damage, disruptive behavior,
            breach of agreements and unresolved or outstanding fees.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Forms of Identification
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Valid photo ID (passport or national ID).
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Florida residents must provide proof of residence – a current
            utility statement in the renter’s name and the same address as on
            the Driver’s License – for example, gas, electric, cable, etc., must
            be presented for address verification.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Local Traveler Restrictions</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Unlimited mileage is not available for Florida Residents – see
            Geographical Restrictions Policy.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Florida residents are allowed a maximum of 150 miles per day, not to
            exceed 3000 miles per month. If the renter exceeds the mileage
            allowance for their rental, a fee will be charged. Local renters are
            restricted from renting premium vehicles.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            The State of Florida requires that all drivers carry proof of
            insurance with them at all times. They are required to provide proof
            of Liability coverage or purchase this at the time of rental.
          </Text>
          <Text style={styles.text}>
            Local renters are required to leave a credit card deposit of 1000
            USD
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Forms of Payment</Text>
        </View>
        <View style={styles.table}>
          <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Method of Payment</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Accepted for local Rental Charges
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Accepted for Deposit</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                Credit Card (VISA / Mastercard)
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>American Express</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Discover</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Diners</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>YES</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Pre-Paid (VISA / Mastercard)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Debit Card</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Electronic Use Card</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            Accepted cards stated above must be in the main driver’s name with
            sufficient funds available to cover the deductibles (excess).
            Failure to provide a valid card may result in the booking being
            cancelled and no funds for the prepaid amount reimbursed.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Deposit / Pre-authorization information
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            Places Rentals offers Insurance to be purchased at the check out
            time.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            We will require a security deposit to be preauthorized on the credit
            card of the main driver. A pre authorization is the method by which
            a deposit amount is ring fenced rather than transacted as a sale.
            The money must be available in the account but will not leave it.
            The amount will not be available to the account holder until
            released by their bank. The period until the funds are released is
            usually between 7 and 15 working days but can be longer depending on
            the type of card and country of issue. A security deposit will be
            withheld on the credit card for various reasons including but not
            limited to Loss of Use/Diminishing of Value (regardless of fault in
            auto accident), unauthorized rental extensions, damage, recovery of
            vehicle, driving rental car outside state of Florida boundaries,
            etc. If you purchase the Excess Reimbursement Policy from a third
            party, the credit card holder will be required to present his / her
            credit card at the time of booking.
          </Text>
          <Text style={styles.text}>
            The deposit will be held on the credit card for various reasons
            including unauthorized rental extensions, recovery of vehicle,
            driving the vehicle outside the state of Florida boundaries, excess
            mileage, refueling costs, toll fees or traffic violations, etc.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Insurance policies purchased from a third party are usually
            deductible / excess reimbursement policies. This means that
            customers are liable for the deductible / excess amount detailed on
            their booking and must pay the deposit/pre authorization amount.
          </Text>
          <Text style={styles.text}>
            In the unlikely event that damage occurs, customers who purchase
            such a policy generally must pay Places Rentals and will be able to
            claim for reimbursement of their costs through the policy purchased
            from the third party. Places Rentals is not qualified to evaluate
            the extent of your existing insurance coverage, and you should
            consult with your insurance provider.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            A minimum of 500 USD deposit will be required in addition to the
            rental charges. Customers who intend to use third party insurance
            will be required to have a minimum of 1000 USD for the deposit.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Only clients with a driver’s license issued outside of the US
            (including all USA territories), will qualify for the inclusive
            product which includes all mandatory insurances.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Insurance Information</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.textBold}>
            BONZA needs to provide insurance information in all categories.
          </Text>
          <Text style={styles.heading3}> Damage Waiver Products </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            The packages below are available to purchase in addition to any of
            the pricing packages detailed above, or in addition to bookings
            placed via third party websites.
          </Text>
        </View>
        <View>
          <Text style={styles.heading3}>
            Deductible (Excess) Reimbursement Policies
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            If you purchase the Deductible (Excess) Reimbursement Policy from a
            third party, the credit card holder will be required to present
            their credit card at the time of booking in order for the rental
            company to authorize the deposit.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Deductible (Excess) insurance policies purchased from a third party
            are usually reimbursement policies. This means customers are liable
            for the full value of the vehicle on their booking and must pay the
            deposit / pre authorization amount.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            In the unlikely event that damage occurs, customers are required to
            pay Places Rentals for all damages to the vehicle incurred, along
            with loss of use, and will be able to claim for reimbursement of
            their costs through the policy purchased from the third party.
          </Text>
        </View>
        <View>
          <Text style={styles.heading3}> Auto Insurance Requirements </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Customers intending to self-insure our vehicles must produce
            sufficient proof of auto insurance, including comprehensive and
            collision coverage. If opting to self-insure, you must carry a full
            coverage policy (physical or electronic), to include both physical
            damage and liability.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Not all insurance companies are accepted – please contact us in
            advance to enquire if your policy / coverage will be accepted.
          </Text>
          <Text style={styles.text}>
            If opting to utilize credit card CDW, please note liability is not
            covered by credit cards and the customer must purchase third party
            liability coverage from the rental company.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Insurance verification must be presented in the form of
            documentation (declaration page of policy), email or via the
            insurance company’s mobile application only. If the insurance
            card/policy does not include the name of the renter, the declaration
            page of the policy showing the renter as covered must be provided,
            or the coverage will not be accepted.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            If proof of insurance cannot be provided at the time of rental,
            customers may be required to purchase insurance – both collision and
            liability coverages – at the time of booking.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Collision Damage Waiver (CDW):</Text>
        </View>
        <View>
          <Text style={styles.text}>
            CDW is not included in the rate for US source reservations and can
            be purchased.
          </Text>
          <Text style={styles.text}>
            The following items are excluded from the CDW: Tires, wheels, rear
            view and side mirrors, windows, windshield, hubcaps, taxi
            (transportation), towing costs, locks, battery, motor, undercarriage
            and interior of car, clutch, replacement keys, damage to oil sump,
            catalytic convertor, radiator.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Customers who intend to use third party insurance will be required
            to have a minimum of 1000 USD for the deposit.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            In most States, in the event of any other loss or damage to the car,
            regardless of fault, your financial responsibility extends to the
            full value of the car at the time of rental, less its salvage value,
            plus expenses for towing, storage impound fees, an administrative
            fee and a reasonable charge for loss of use. If a debit card or cash
            is allowed at the time of booking for the security deposit, the
            purchase of our CDW plan will be mandatory. (This description is for
            informational purposes only. Please refer to the materials provided
            at the time of rental for specific policy provisions and
            exclusions).
          </Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Vehicle Group</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>All vehicle groups</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Price per day</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>$29.99 USD per day</Text>
              </View>
            </View>
          </View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Please note the purchase of a CDW is mandatory when renting 12-15
            passenger vans. This is considered commercial insurance and is
            mandatory to be purchased at the time of booking – personal policies
            will not be accepted.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            *Depends on CDW plan selected, with corresponding deductibles.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Optional Insurance Coverage</Text>
        </View>
        <View>
          <Text style={styles.text}>
            We may offer for sale Optional Insurance Products, (“Optional
            Insurance Products”), including Renter’s Liability Protection,
            Personal Accident Insurance and Personal Effects Coverage.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Optional Insurance Products that we sell may provide coverage that
            duplicates coverage provided by your personal automobile liability
            policy, your homeowner’s insurance policy, or by another source of
            coverage. We are not qualified to evaluate the extent of your
            existing auto liability coverage.
          </Text>
        </View>
        <View>
          <Text style={styles.heading3}>
            Renters Liability Protection (RLP)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            This is available for an additional daily charge of 11.99 USD for
            each rental day depending on the deductible limit taken
          </Text>
        </View>
        <View>
          <Text style={styles.heading3}>
            Supplemental Liability Insurance (SLI)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            This is available for an additional daily charge of 15.99 USD for
            each rental day.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            This is available for an additional daily charge for each rental
            day. SLI is a secondary insurance product that picks up where
            primary liability (state statutory limits) leaves off. When combined
            with primary liability coverage, SLI provides the renter and any
            authorized driver up to 1,000,000 USD of liability protection for
            third party claims due to bodily injury or property damage.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            In order for SLI to pay on a claim, however, the state statutory
            limits must first at least be satisfied. The policy will respond
            only after all other available insurance has been exhausted. The
            purchase of SLI will be required if renter does not own a vehicle or
            auto insurance policy in their name. If insuring your rental car
            with a credit card which has rental car benefits, the purchase of
            SLI will be required, unless renter has proof of liability auto
            insurance which must be presented at the time of pickup.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Personal Accident Insurance (PAI)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            This is available for an additional daily charge of 16.99 USD for
            each rental day.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            This coverage is available in combination with Personal Effects
            Coverage (PEC) for an additional daily charge for each rental day.
            PAI coverage: Benefit Schedule Renter/Each Passenger Accidental
            Death 250,000 / 100,000 USD, Accidental Medical Expenses
            (reimbursed) not to exceed 2,500 USD, Ambulance Expenses not to
            exceed 500 USD. Total benefits for any one accident are limited to
            458,000 USD and are payable in addition to any other coverage for
            which you, authorized drivers or your passengers are eligible.
            However, your benefits provided by other coverage may be affected by
            the PAI benefits. If this coverage is accepted, the benefits for you
            apply to all accidental injuries during your rental period,
            regardless of whether you are actually in the car. Your passengers
            are also covered, but only for incidents occurring while they occupy
            the Green Motion rental car.
          </Text>
        </View>
        <View>
          <Text style={styles.heading3}>Personal Effects Coverage (PEC)</Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            This is offered in combination with PAI, is an option that insures
            against risk of theft or damage to help protect your personal
            belongings while renting from Places Rentals. The maximum coverage
            per person is 750 USD with the maximum total benefit for any one
            occurrence, regardless of the number of persons, being 2,000 USD.
            There is no deductible for PEC. Please note that PAI and PEC are not
            available separately and must be taken in combination.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Some exclusions and coverage conditions will apply. Please visit our
            website at www.places.rentals for more information to check the
            terms and conditions. brochure for terms and conditions of the above
            products.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Breakdown Assistance</Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Roadside assistance is optional and available to purchase at the
            counter.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Roadside assistance covers items such as key replacement/delivery,
            flat tires, towing unrelated to an accident, jumpstart, lockout
            service and limited fuel delivery to reach the nearest service
            station. Roadside assistance does not cover the cost for replacement
            tires, unless the tire was faulty or not within proper tread levels.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Transferable Insurance Coverages</Text>
        </View>
        <View>
          <Text style={styles.text}>
            Please note that if your credit card company or other insurance
            providers offer Collision Damage or similar coverage transferable to
            rental vehicles, it is your responsibility to verify whether it is
            valid in your rental country. If you wish to waive similar coverage
            offered by the Supplier, you may need to present written proof of
            coverage from the insurance provider. If the proof is deemed
            insufficient, the Supplier may require you to purchase their
            coverage.
          </Text>
          <Text style={styles.text}>
            The proof of coverage from the coverage provider must clearly state
            the following information: *policy number *any deductible amounts,
            if applicable
          </Text>
          <Text style={styles.text}>
            If you choose to use your credit card company's coverage, you must
            use the same credit card to pay. The credit card must be in your
            name.
          </Text>
          <Text style={styles.text}>
            This Supplier only accepts insurance issued by a limited number of
            providers. Please contact us directly to verify whether your
            insurance is accepted.
          </Text>
          <Text style={styles.text}>
            Renters who intend on using their supplemental rental car insurance
            benefits issued through their credit card, must be U.S. based
            residents.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Age Restriction and Charges</Text>
        </View>
        <View style={styles.section}>
          <View style={{ ...styles.table, ...styles.section }}>
            <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Driver Age Requirements</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Minimum age</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>21</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Maximum age</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>85</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Young Driver Surcharge</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  Young driver surcharge applies to ages 21 – 25. Charge 25.00
                  USD per day.
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Senior Driver Surcharge</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  70 + years of age. Charge 10 USD per day
                </Text>
              </View>
            </View>
          </View>
          <Text style={styles.text}>
            *When the correct age of the renter is entered when requesting a
            quote from the website at www.places.rentals, the Young Driver and
            Senior driver surcharge is included in the rate provided.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Fuel Policy and Costs</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Green Motion operates a like for like Fuel Policy; renters are
            requested to return the vehicle with the same level of fuel as was
            supplied at the time of rental.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Missing fuel will be charged at 5.99 USD / gallon and an additional
            US$49.99 Refueling Fee may apply.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            You may be required to leave a fuel deposit reserved on your credit
            card which will be released when you return the car with the same
            amount of fuel. Missing fuel will be charged on your return. The
            price per gallon charged by the rental agent may be significantly
            higher than the price at the local service station.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.textBold, ...styles.section }}>
            Prepaid gas option may be available at the time of rental:
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.textBold, ...styles.section }}> </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Mileage limitations and Cost</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>Basic – 150 miles per day</Text>
          <Text style={styles.text}>
            If the renter exceeds the mileage allowance for their rental, a fee
            of 0.25 USD per mile will be charged.
          </Text>
          <Text style={styles.text}>
            *Unlimited mileage is not available for Florida Residents - see
            Geographical Restrictions Policy.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Cross Border Restrictions and Costs
          </Text>
          <Text style={{ ...styles.textBold, ...styles.section }}>
            WILL APPLY FOR FLORIDA RESIDENCE ONLY
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Geographic / Cross Border Restrictions and Costs Unlimited mileage
            is available only when traveling within the stated geographical
            area. Use of the vehicle is restricted to within the State of
            Florida.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            For those traveling outside of the geographical restricted area, a
            mileage charge will apply. At the time of rental, mileage packages
            are available.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Mileage is unlimited for all customers arriving into TPA
            international airport with a return ticket. *FLresidents excluded
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Third Party Liability (TPL) (SLI) and Collision Damage Waiver (CDW)
            are included for international customers who reside outside the
            United States. All US residents will be required to purchase Third
            Party Liability (TPL)(SLI) and Collision Damage Waiver (CDW), or to
            use their own auto insurance.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Optional equipment list
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            All optional extras are subject to price changes without notice;
            additionally, optional extras are subject to availability.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Baby seat, child seat, booster seat, roadside assistance,
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Unlimited Toll fee/Sun pass: 12.99 USD per day
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Installation of Baby / Child Seats
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            The renter is responsible for the installation of child/ baby seats;
            Places Rentals will not install child/baby seats.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Additional driver cost
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Additional Driver: 25.99 USD per trip*exclusions may apply for
            spouses – confirm with rental location.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Toll Related Costs
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            All parking, toll and traffic violations are the renter's
            responsibility.
          </Text>
          <Text style={styles.text}>
            You have the option to purchase a toll pass for 12.99 USD per day -
            unlimited passes / day.
          </Text>
          <Text style={styles.text}>
            If the Toll Pass is not purchased, you will authorize us to process
            and bill you for unpaid toll violations incurred by you or assessed
            against us or the vehicle during the rental, plus a 29 USD admin fee
            for EACH violation incurred during the time of rental.
          </Text>
          <Text style={styles.text}>
            Most rental vehicles are outfitted with an automatic toll processing
            system, and this may automatically process toll payments when
            crossing a toll camera, even if you have chosen not to accept the
            offered toll package. If this occurs, you will be responsible for
            paying all outstanding tolls and administrative fees applied to your
            rental.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading1}>Safety Policies</Text>
        </View>
        <View>
          <Text style={styles.heading3}>Accidents</Text>
        </View>
        <View>
          <Text style={styles.text}>
            Failure to report accidents truthfully and in keeping with the
            rental terms and conditions may result in insurance and optional
            products being null and void. Failure to cooperate or providing
            false information in connection with a claim will result in the
            driver being liable for all claims costs.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            In the event that a customer is involved in an accident during the
            rental period, a replacement vehicle is not guaranteed.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Lost Keys
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            A charge will apply if keys are lost or damaged. Please note that if
            a vehicle is stolen and keys are not returned to the rental
            location, the renter will be deemed negligent.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Lost Property
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            In the event that personal belongings are left in the vehicle or at
            the rental station, it must be reported at the earliest convenience
            to Places Rentals which will endeavor to find and return the item/s.
            However, Green Motion and its representatives will not be held
            liable for any loss or damage to belongings left in the hire car or
            within the rental premises.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Vehicle Trackers
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Some of the vehicles contain tracking devices which will be used in
            accordance with our data protection policy.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Cancellation / No Show Policy / Cost
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Delayed pick-ups/ No show policy
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Reservations will be held for 2 hours from the specified reservation
            time after which they will be considered as a no show. If the renter
            arrives after this time, then a vehicle is not assured. Places
            Rentals will do everything possible to provide a vehicle.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            This may include offering a different vehicle and rates may be
            different to the original booking. In the event of a no show, the
            booking is non-refundable. Flight numbers provided are for
            informational purposes only. It is the responsibility of the renter
            to inform the location of any delays.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Cancellation Policy
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            All cancellations must be made in writing at least 24 hrs prior to
            arrival or canceled through your account on the Places Rentals
            website or on the website where originally booked. Please note that
            telephone cancellations will not be accepted.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Cancellation Policy for Basic Package
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            Non-refundable
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>Other </Text>
        </View>
        <View>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            {" "}
            Pricing{" "}
          </Text>
          <Text style={styles.textBold}>Places Rentals </Text>
          <Text style={styles.textBold}>
            Deductible : 1,000 USD (Tires, Wheels, Windshield not covered){" "}
          </Text>
          <Text style={styles.textBold}>
            Deposit: 250 - 1,000 USD * Please see Deposit / Pre-authorization
            Information Limited to 150 miles per day{" "}
          </Text>
          <Text style={styles.textBold}>Non-cancellable </Text>
          <Text style={styles.textBold}>Non-refundable </Text>
          <Text style={styles.textBold}>No changes/modifications </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.textBold, ...styles.section }}>
            All of the packages above include the following within the rates:
          </Text>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            Third party liability (TPL)(SLI) * See guide. Collision damage
            waiver (CDW) * See guide Taxes
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Vehicle Condition</Text>
        </View>
        <View>
          <Text style={styles.text}>
            Vehicles must be returned to the location/drop off point in the same
            condition as when it was picked up/delivered. It is the
            responsibility of the renter to ensure that the vehicle is
            thoroughly checked prior to taking custody of the vehicle. During
            the rental, the renter accepts responsibility for maintaining the
            roadworthiness of the vehicle and agrees to take remedial action to
            avoid unnecessary cost/damage. This will include maintaining oil
            levels, tire pressures and other common sense actions to maintain
            the vehicle. Costs incurred to maintain the vehicle will generally
            be reimbursed only if they were authorized in advance by rental
            agreement.
          </Text>
          <Text style={styles.text}>
            The renter must return the vehicle in a clean condition (normal wear
            and tear excepted). If the vehicle is exceptionally dirty upon
            return or if there is damage to the seats, seat upholstery or the
            carpets, an additional charge for deep cleaning will apply. This
            charge will be dependent upon the actual state of the vehicle when
            returned. Any damage caused to the vehicle, is the responsibility of
            the renter.
          </Text>
          <Text style={styles.text}>
            In keeping with industry standards and association guidelines, short
            term vehicle rental does not have a margin of fair wear and tear for
            damage. A short term rental is any hire for which the duration is
            less than 30 consecutive days. Any change to the condition of the
            vehicle during the rental will be the responsibility of the
            customer.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Smoking Policy</Text>
        </View>
        <View>
          <Text style={styles.text}>
            A strict no smoking and vaping policy is in place for all vehicles.
            If you have smoked or allowed smoking within the vehicle, charges
            will apply. This includes allowing cigarette, cigar, or marijuana
            smoke or ash into the vehicle, which would imply the above, as well
            as vaping. A minimum fee of 400 USD will apply.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Transporting Animals in Vehicles</Text>
        </View>
        <View>
          <Text style={styles.text}>
            No animals are permitted in our rental vehicles. Exceptions may be
            made, at the location’s discretion for registered service animals
            only. A cleaning fee may be applied to rectify any soiling due to
            animal transportation. A minimum fee of 400 USD will apply.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Rental Extensions</Text>
        </View>
        <View>
          <Text style={styles.text}>
            It may not always be possible to extend the rental. If an extension
            is granted, the rental will be charged at the prevailing rate which
            may be higher or lower than the original booking rate.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Ferry</Text>
        </View>
        <View>
          <Text style={styles.text}>Cars are not permitted on ferries.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Non-authorized rental extension</Text>
        </View>
        <View>
          <Text style={styles.text}>
            If the renter fails to return the vehicle within the specified time,
            a penalty charge of at least 200 USD will be applied and the renter
            will be declined for future bookings.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Early Returns/Unused Rental Days</Text>
        </View>
        <View>
          <Text style={styles.text}>
            No refunds (partial or full) will be given for rentals ended early
            including the purchase of ancillary items, late collections, no
            shows or cancellations made after the rental start date.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Late Returns</Text>
        </View>
        <View>
          <Text style={styles.text}>
            A vehicle is rented for a contracted period as detailed on the
            rental agreement. The return day and time as selected by the renter
            at the time of reservation will be enforced regardless of delayed
            pickup time. If the vehicle is returned outside the grace period,
            there will be an additional charge. Charges will be withdrawn from
            the card provided for the security deposit. Grace period: 59 minutes
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Changes/Modifications</Text>
        </View>
        <View>
          <Text style={styles.text}>
            Reservation changes/modifications cannot be guaranteed and may be
            treated as a new booking. Changes to an existing reservation must be
            requested at least 24 hours before the start of your rental. Please
            note that telephone modification requests will not be accepted.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Fines, Penalties and Administration Charges
          </Text>
        </View>
        <View>
          <Text style={styles.text}>
            It is the responsibility of each renter to cover the cost of any
            parking fines and traffic violations. When fines or traffic
            violations occur and a Places Rentals location is involved in
            administering the charge, then an administration fee will be charged
            to the renter.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Complaints</Text>
        </View>
        <View>
          <Text style={styles.text}>
            All complaints should be made in writing through the Online Contact
            Form found on www.places.rentals within 30 days from the last day of
            your rental. Although Green Motion does try to resolve all cases
            brought to our attention, those raised after this period may not be
            considered. If at any point during the investigation process
            communication ceases for a period of more than 30 days, the case
            will be considered closed and no further action will be taken.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Data Privacy and Security</Text>
        </View>
        <View>
          <Text style={styles.text}>
            All information relating to data privacy and data protection when
            using a Places Rentals service will be contained on our privacy
            policy which is located on our website.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>Promotions</Text>
        </View>
        <View>
          <Text style={styles.text}>
            Any promotional codes used at the time of booking are applied and
            therefore included within the displayed price. Any terms associated
            with the promotions must be adhered to at the time of collection.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading3, ...styles.section }}>
            General Terms
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            All bookings are subject to our terms and conditions. Places Rentals
            Limited cannot accept liability for any charges incurred on
            documentation signed for and accepted locally. Places Rentals
            Limited will not be held responsible for anyone driving under the
            influence of alcohol or drugs causing willful damage, off road
            driving or driving without due care and attention.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            The renter will accept responsibility in respect of such claims.
            Client is responsible for the entire amount of the damage without
            damage excess (deductible) limitations with all belonging costs
            caused by such behavior. This list is not exclusive. See the Rental
            Agreement Jacket Terms and Conditions for additional “prohibited
            uses”
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Booking confirmation vouchers will be issued to the renter via email
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            When comprehensive insurance is taken out it is to provide the
            renter with entitlement to drive and covers the vehicle in the event
            of an accident. In the case where the renter is found negligent.
            Places Rentals Limited and its locations reserve the right to pursue
            the renter for the recovery of all losses incurred.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            Places Rentals Limited reserves the right to cancel a rental and
            refuse the service at any time. Terms and Conditions Updated May
            2024 and may be subject to change at any time.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading1, ...styles.section }}>
            Primary Driver
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Name: {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            DL#:{" "}
            {stripeVerification?.person_details.document_number === ""
              ? "N/A"
              : stripeVerification?.person_details.document_number}{" "}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading3}>
            Issued Date: {stripeVerification?.person_details.issued_date?.month}
            /{stripeVerification?.person_details.issued_date?.day}/
            {stripeVerification?.person_details.issued_date?.year}
          </Text>
        </View>
        {/* <View style={styles.section}>
          <Text style={styles.heading3}>
            DL Expiration:{" "}
            {stripeVerification?.person_details.expiration_date.day}/
            {stripeVerification?.person_details.expiration_date.month}/
            {stripeVerification?.person_details.expiration_date.year}
          </Text>
        </View> */}
        <View style={styles.section}>
          <Text style={styles.heading1}>Insurance</Text>
        </View>
        {selectedBonzahProduct.length != 0 ? (
          selectedBonzahProduct?.map((selectedBonzahOption) => (
            <View style={styles.section}>
              <Text style={styles.heading3}>
                Insurance Name : {selectedBonzahOption.name}
              </Text>
            </View>
          ))
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Policy # : {axleInsurance?.results["policyNumber"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 ? (
          <View style={styles.section}>
            <Text style={styles.heading3}>Insurance Provided by : Bonzah</Text>
          </View>
        ) : (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance Company : {axleInsurance?.results["carrier"]}
            </Text>
          </View>
        )}
        {selectedBonzahProduct.length != 0 && (
          <View style={styles.section}>
            <Text style={styles.heading3}>
              Insurance agreement :{" "}
              {bonzahCertificate !== null
                ? bonzahCertificate
                : "Will be provided in the email"}
            </Text>
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.heading1}>Signature</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.heading1,
              ...{ fontFamily: signatureStylesText[selectedSignatureIndex] },
            }}
          >
            {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadLink: React.FC = () => {
  const {
    selectedBonzahProduct,
    stripeVerification,
    selectedSignatureIndex,
    axleInsurance,
  } = useApp();
  return (
    <div>
      <PDFDownloadLink
        document={
          <RentalAgreement
            selectedSignatureIndex={selectedSignatureIndex}
            stripeVerification={stripeVerification!}
            selectedBonzahProduct={selectedBonzahProduct}
            axleInsurance={axleInsurance}
          />
        }
        fileName="rental_agreement.pdf"
        className=" cursor-pointer"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Rental Agreement"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RentalAgreement;
