import { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import { getCarsListing } from "utils/apiUtil";
import toast from "react-hot-toast";
import CarsListingModel, { CarsListing } from "models/CarsListingModel";
import FleetCard from "components/FleetCard/FleetCard";
import { useApp } from "data/app-provider";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Our Fleet",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  const { ourFleet,ourFleetLoading } = useApp();
  const [carsLoading, setCarsLoading] = useState<boolean>(true);

  const renderCard = (car: CarsListing) => {
    return <FleetCard key={car.l_id} data={car} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {ourFleetLoading ? (
          <div className="flex justify-center items-center col-span-full">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-md border-t-transparent border-gray-300"></div>
        </div>
        ) : ourFleet!.listings.length > 4 ? (
          ourFleet?.listings.slice(-4).map((car) => renderCard(car))
        ) : (
          ourFleet?.listings.map((car) => renderCard(car))
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
