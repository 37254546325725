import React, { ReactNode } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-car-detail/constant";
// import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { useApp } from "data/app-provider";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const thisPathname = useLocation().pathname;

  const {
    selectedInsuranceProtection,
    axleInsurance,
    stripeVerification,
    selectedCar,
    user,
    setIsAuthPanelOpen
  } = useApp();
  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    console.log(thisPathname);
    if (thisPathname?.includes("/listing-stay-detail")) {
      return listingStayImageGallery;
    }
    if (thisPathname?.includes("/listing-car-detail")) {
      return selectedCar?.listing_images.map(
        (item, index): ListingGalleryImage => {
          return {
            id: index,
            url: item.path,
          };
        }
      );
    }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }

    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div> */}
        <div className="block lg:hidden">
          <ButtonPrimary
            sizeClass="px-5 sm:px-7 py-3 !rounded-md w-full"
            onClick={() => {
              if (user == null) {
                sessionStorage.setItem("redirectUrl", "listing-car-detail");
                toast.error("Please Login/Signup to continue");
                setIsAuthPanelOpen(true);
                return;
              }
              if (stripeVerification == null) {
                toast.error(
                  "Please complete stripe verification to move forward"
                );
              } else if (
                (axleInsurance?.results["isActive"] == false ||
                  axleInsurance == null) &&
                selectedInsuranceProtection.length === 0
              ) {
                toast.error(
                  "Either verify your insurance with axle or select an inurance coverage option"
                );
              } else {
                navigate("/checkout");
              }
            }}
          >
            Reserve
          </ButtonPrimary>
        </div>
        {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
      </div>

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};

export default DetailPagetLayout;
