import React from "react";

function FortlandsContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Hotels in Fort Lauderdale Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            AC Hotel Fort Lauderdale Beach 3029 Alhambra St Fort Lauderdale, FL
            33304 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            The Conrad by Hilton Fort Lauderdale Beach 551 N Fort Lauderdale
            Beach Blvd Fort Lauderdale, FL 33304 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            The Conrad by Hilton Fort Lauderdale Beach 551 N Fort Lauderdale
            Beach Blvd Fort Lauderdale, FL 33304 United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Restaurants in Fort Lauderdale Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Yolo 333 E Las Olas Blvd Fort Lauderdale, FL 33301 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            Nube Rooftop 505 N Fort Lauderdale Beach Blvd FL 26 Fort Lauderdale,
            FL 33304 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            Coconuts 429 Seabreeze Blvd Fort Lauderdale, FL 33316 United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FortlandsContent;
