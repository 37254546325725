import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useApp } from "data/app-provider";
import { formatDateTime } from "utils/converSelectedDateToString";
import ButtonCircle from "shared/Button/ButtonCircle";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const {
    user,
    pickupLocation,
    dropOffLocation,
    selectedDropOffLocationExtra,
    startDate,
    endDate,
    selectedCar,
    selectedInsuranceProtection,
    subTotal,
    discount,
    discountName,
    discountPercentage,
    extrasTotal,
    totalTax,
    tripFee,
    grossTotal,
    protection,
    couponResponse,
    calculateCouponDiscount,
    setCouponResponse,
  } = useApp();

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-md sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulations 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <h3 className="text-2xl font-semibold">
              Your booking has been confirmed.
            </h3>
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-md overflow-hidden">
                <img
                  alt=""
                  className="absolute inset-0 object-cover w-full"
                  sizes="200px"
                  src={
                    (selectedCar?.listing_images?.length ?? 0) > 0
                      ? selectedCar?.listing_images[0].path
                      : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold">Location</h3>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-md flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Pick up Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {pickupLocation?.name}
                </span>
              </div>

              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Drop off Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                    {dropOffLocation
                      ? dropOffLocation?.name
                      : pickupLocation?.name}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-semibold">Date & Time</h3>
            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-md flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Pick up Date & Time
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {formatDateTime(startDate)}
                </span>
              </div>

              <div className="flex flex-col flex-1 p-5">
                <span className="text-sm text-neutral-400">
                  Drop off Date & Time
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                    {formatDateTime(endDate)}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="w-full">
              <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
                Rental Bill
              </h2>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Prices may change depending on the length of the rental and the
                price of your rental car.
              </p>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Subtotal
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {" "}
                      ($
                      {((selectedCar?.pricing?.amount ?? 0) / 100).toFixed(
                        2
                      )} *{" "}
                      {(
                        subTotal /
                        ((selectedCar?.pricing?.amount ?? 1) / 100)
                      ).toFixed(2)}{" "}
                      days)
                    </span>
                  </div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${subTotal.toFixed(2)}
                  </span>
                </div>
              </div>
              {selectedInsuranceProtection?.map(
                (selectedBonzahOption, index) => (
                  <div className="mt-4" key={index}>
                    <div className="flex justify-between items-center">
                      <div>
                        <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                          {selectedBonzahOption?.title ?? "Insurance Coverage"}{" "}
                        </span>
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {" "}
                          ($
                          {((selectedBonzahOption?.price ?? 0) / 100).toFixed(
                            2
                          )}{" "}
                          *{" "}
                          {selectedBonzahOption?.price === 0
                            ? 0
                            : (
                                protection /
                                ((selectedBonzahOption?.price ?? 1) / 100)
                              ).toFixed(2)}{" "}
                          days)
                        </span>
                      </div>
                      <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                        ${protection.toFixed(2)}
                      </span>
                    </div>
                  </div>
                )
              )}
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Custom Location Charges
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${((pickupLocation?.price ?? 0) / 100).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Delivery Charges (Drop-off)
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    $
                    {((selectedDropOffLocationExtra?.price ?? 0) / 100).toFixed(
                      2
                    )}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Extras
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${extrasTotal.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Tax
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${totalTax.toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Trip Fee
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${tripFee.toFixed(2)}
                  </span>
                </div>
              </div>

              {discount !== 0 && (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Discount
                    </span>
                    <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                      -${discount.toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
              {discount !== 0 && (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      {discountName}
                    </span>
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {discountPercentage}%
                    </span>
                  </div>
                </div>
              )}

              {couponResponse !== null && (
                <div className="mt-4 ">
                  <div className="flex justify-between items-center">
                    <div className="flex flex-wrap  border border-grey rounded-md py-1">
                      <span className="text-lg font-normal text-gray-700 dark:text-gray-300 pl-4">
                        {couponResponse.coupon.code}
                      </span>
                      <span className="text-lg text-green-600 font-medium pl-1">
                        (
                        {couponResponse?.coupon.type === "amount"
                          ? `$${couponResponse.coupon.amount / 100} off`
                          : `${couponResponse?.coupon.amount}% off`}
                        )
                      </span>
                      <ButtonCircle
                        onClick={() => {
                          setCouponResponse(null);
                        }}
                        size="w-6 h-6"
                        className=" self-center mr-2 ml-4"
                      >
                        <XMarkIcon className="w-4 h-4" />
                      </ButtonCircle>
                    </div>
                    <span className="text-2xl text-green-600 font-semibold">
                      -${calculateCouponDiscount()}
                    </span>
                  </div>
                </div>
              )}

              <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      Total Rental Price
                    </span>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      Overall price rental
                    </p>
                  </div>
                  <div>
                    <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                      ${grossTotal().toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-5">
          <ButtonPrimary href="/">Go to Home</ButtonPrimary>
          <ButtonPrimary href="/account-bookings">Go to Account</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
