import React from "react";

function DeerfieldBeachContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Hotels in Deerfield Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Embassy Suites Deerfield Beach 1136 E Hillsboro Blvd Deerfield
            Beach, FL 33441 United States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            By far the best overall beachfront hotel option in all of Deerfield
            Beach in our humble opinion. Rates start at $150 / night in the low
            season
          </span>
          <li className="text-lg font-medium text-white mt-8">
            Royal Blues Hotel45 NE 21st Ave Deerfield Beach, FL 33441 United
            States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            Higher end option right in the heart of Deerfield Beach Close to the
            Pier and all the action! Room Rates start at $350 / per night in the
            low season. Smaller hotel with heavy attention to detail
          </span>
          <li className="text-lg font-medium text-white mt-8">
            Hampton Inn Boca Raton- Deerfield Beach 660 WHillsboro Blvd
            Deerfield Beach, FL 33441 United States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            Great location- right next to 95 and recently renovated. Perfect for
            business travelers not looking to be close to the beach. Rates start
            at $100 / night in the low season
          </span>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Restaurants in Deerfield Beach Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Charm City Burger Company 1136 E Hillsboro Blvd Deerfield Beach, FL
            33441 United States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            Looking for the best burger you’ll ever have? Head over to Charm
            City Burger and indulge in this local favorite. You won’t be
            disappointed! Regular restaurant style burgers cooked to order with
            FRESH hand cut fries- stop by before or after the beach!
          </span>
          <li className="text-lg font-medium text-white mt-8">
            Oceans 234 234 North Ocean Blvd Deerfield Beach, FL 33441 United
            States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            Great location, right on the Deerfield Beach Boardwalk near all the
            action. Amazing ocean front views and even better food! Local
            seafood + american style dishes the whole family can enjoy. The best
            beachfront restaurant in all of Deerfield Beach!
          </span>
          <li className="text-lg font-medium text-white mt-8">
            The Cove Waterfront Restaurant + Tiki Bar 1754 SE Third Ct Deerfield
            Beach, FL 33441 United States
          </li>
          <span className="text-lg font-medium text-[#9CA3AF]">
            Nestled along the intercoastal, this Tiki bar offers live music,
            action and awesome food. Looking to rent a boat? Book a boat ride or
            sunset cruise right at the Cove in Deerfield Beach!
          </span>
        </ul>
      </div>
    </div>
  );
}

export default DeerfieldBeachContent;
