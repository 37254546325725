import React from "react";

function CoconutCreekContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Hotels in Coconut Creek Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Hampton Inn & Suites Coconut Creek 5740 North State Road 7 Coconut
            Creek, FL 33073 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            Residence Inn Marriott Fort Lauderdale Coconut Creek 5730 SR-7 N
            Coconut Creek, FL 33073 United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Restaurants in Coconut Creek Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            NYY Steak 5550 NW 40th St Coconut Creek, FL 33073 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            The BBQ Boutique 4209 W Hillsboro Blvd Coconut Creek, FL 33073
            United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CoconutCreekContent;
