import { FC } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { Link } from "react-router-dom";
import { BookingsModel } from "models/BookingsModel";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface StayCardProps {
  className?: string;
  data: BookingsModel;
  size?: "default" | "small";
  isUpcoming?: boolean;
  cancelFunction?: any;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  isUpcoming,
  data,
  cancelFunction,
}) => {
  const {
    id,
    email,
    start_date_time, // ISO 8601 string
    end_date_time, // ISO 8601 string
    pickup_location,
    drop_off_location,
    total,
    vehicle_name,
    vehicle_id,
    vehicle_img,
    booking_status,
  } = data;
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full bg-fleet-bg">
        <img
          src={vehicle_img}
          alt="name"
          className="h-[200px] object-cover bg-fleet-bg"
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{vehicle_name}</span>
            </h2>
          </div>

          <span className=" text-sm">{pickup_location}</span>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">${total.toFixed(2)}</span>
        </div>
        {isUpcoming == true && (
          <ButtonPrimary
            className=" bg-red-500 w-full"
            onClick={() => {
              cancelFunction(id);
            }}
          >
            Cancel Booking
          </ButtonPrimary>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard bg-fleet-bg group relative dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-md overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={""}>{renderContent()}</Link>
    </div>
  );
};

export default StayCard;
