import React from "react";

function DavieContent() {
  return (
    <div>
      {" "}
      <div className="p-8 max-w-3xl mx-auto">
        <span className="mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Hotels in Davie Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Seminole Hard Rock Hotel and Casino 1 Seminole Way Hollywood, FL
            33314 United States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            Holiday Inn Express Davie 2540 Davie Rd Davie, FL 33317 United
            States
          </li>

          <li className="text-lg font-medium text-white mt-8">
            Hyatt Place Plantation / Fort Lauderdale 8530 W Broward Blvd
            Plantation, FL 33324 United States
          </li>
        </ul>

        <span className="mt-20 mb-12 text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center text-white">
          Best Restaurants in Davie Florida:
        </span>
        <ul className="list-decimal">
          <li className="text-lg font-medium text-white">
            Cafe Bait 8819 Stirling Rd Hollywood, FL 33328 United States
          </li>
          <li className="text-lg font-medium text-white mt-8">
            Francisca 2258 S University Dr Davie, FL 33328 United States
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DavieContent;
